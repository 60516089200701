<template>
    <modal :name="modal_name" class="final-modal" transition="nice-modal-fade">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">CALCULATION</span>
                <button type="button" class="closeModal" aria-label="Close" @click="hideVarianceCost()">
                    <span aria-hidden="true">
                        <i class="icon icon-close"></i>
                    </span>
                </button>
            </div>
            <div class="v-modal-body pt-0 pb-0">
                <div class="v-modal-layout d-form">
                    <div class="m-6 mb-13">
                        <div class="border-radius bg-secondary pt-3 pb-3">
                            <h6 class="font-weight-bold text-white text-center">VARIANCE COST =</h6>
                            <h6 class="font-weight-bold text-white mt-2 text-center">STOCK COUNT COST - POS FINAL QUANTITY COST</h6>
                        </div>
                        <div class="d-flex mt-7">
                            <div class="quantity-details bx-shadow">
                                <span class="font-weight-bold d-block">Stock Count Cost</span>
                                <span class="text-secondary d-block pt-3">1,000</span>
                            </div>
                            <div class="quantity-details pl-2 pr-2">
                                <span class="font-weight-bold d-block">POS Final Quantity Cost</span>
                                <span class="text-secondary pt-3 d-block">$ 5,000.00</span>
                            </div>
                        </div>
                        <div class="final-cost d-flex pr-3 pt-2 mt-7 justify-content-around">
                            <div class="row w-100">
                                <div class="col-lg-6 text-center">
                                    <h6 class="text-uppercase ml-6" style="font-weight: 700 !important;">VARIANCE COST</h6>
                                </div>
                                <div class="col-lg-6 text-center">
                                    <h6 class="text-secondary ml-3" style="font-weight: 700 !important;">$ 4,500,000.00</h6>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="final-cost d-flex pl-3 pr-3 pt-2 mt-7 justify-content-around">
                            <h6 class="font-weight-bold" style="font-weight: 600;">VARIANCE COST</h6>
                            <h6 class="text-secondary font-weight-bold" style="font-weight: 600;">$ 4,500,000.00</h6>
                        </div> -->
                    </div>
                </div>
            </div>
           
        </div>
    </modal>
</template>
<script>
export default {
    props:['modal_name'],
    methods:{
        hideVarianceCost() {
            this.$modal.hide(this.modal_name);
        }    
    }
}
</script>
<style scoped>
.border-radius{
    border-radius: 12px;
}
.quantity-details{
    flex: 1 1 250px;
    font-size: 16px;
    text-align: center;
}
.bx-shadow{
    box-shadow: 3px 0 0px -2px #989898;
}
.final-cost{
    border-top: solid 1px #989898;
    border-bottom: solid 1px #989898;
}
</style>